<figure
  *ngIf="task"
  class="avt"
  [ngClass]="'avt--' + size"
>
  <aw-task-status
    *ngIf="!hideStatus"
    [status]="task.taskStatus"
    [size]="size"
  />

  <figcaption
    class="avt__text"
    [class.avt__text--multiline]="multiline"
  >
    <aw-project-avatar
      *ngIf="task.project && size !== sizes.xs && !hideProject"
      [project]="task.project"
      [size]="sizes.xs"
      [link]="link"
    />

    <span
      *ngIf="showText"
      class="avt__text__primary"
      [class.avt__text__link]="link"
      [ngStyle]="{'-webkit-line-clamp': lines}"
      [attr.title]="task.name"
      (click)="linkClick($event)"
    >
      {{ task.name }}
    </span>
  </figcaption>
</figure>
