import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core'
import { Size } from '@awork/_shared/types/size'
import { Task } from '@awork/features/task/models/task.model'
import { ProjectAvatarComponent } from '../../../project/components/project-avatar/project-avatar.component'
import { NgClass, NgIf, NgStyle } from '@angular/common'
import { TaskStatusComponent } from '../task-status/task-status.component'
import { Router } from '@angular/router'
import { AvatarPixelSizes } from '../../../../_shared/types/avatar-sizes'
import { getTaskDetailNavigationRoute } from '@awork/_shared/functions/task-detail-navigation'

type TaskAvatarSize = Size.xs | Size.s | Size.m

@Component({
  selector: 'aw-task-avatar',
  templateUrl: './task-avatar.component.html',
  styleUrls: ['./task-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, NgStyle, ProjectAvatarComponent, TaskStatusComponent]
})
export class TaskAvatarComponent {
  @Input({ required: true }) task: Task
  @Input() size: TaskAvatarSize = Size.m
  @Input() hideProject = false
  @Input() hideStatus = false
  @Input() link = false
  @Input() multiline = false
  @Input() lines = 2
  @Input() showText = true

  protected readonly sizes = Size
  protected readonly avatarPixelSizes = AvatarPixelSizes

  constructor(
    private router: Router,
    private injector: Injector
  ) {}

  /**
   * Navigates to task details
   */
  linkClick(event: MouseEvent | TouchEvent) {
    if (this.link) {
      event.stopPropagation()

      const taskRoute = getTaskDetailNavigationRoute(this.task, this.injector)
      this.router.navigate(taskRoute, {})
    }
  }
}
