import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
  WritableSignal
} from '@angular/core'
import { WithGlobals } from '../../../classes/with-globals'
import { DropdownOption } from '@awork/_shared/models/select-option.model'
import { NgClass, NgFor, NgIf } from '@angular/common'
import { PopupComponent } from '../../layout/popup/popup.component'
import { CdkConnectedOverlay, ConnectedPosition, OverlayModule } from '@angular/cdk/overlay'
import { popIn } from '../../../animations/pop-in.animation'
import { InlineSearchFieldComponent } from '../inline-search-field/inline-search-field.component'
import { NavListDirective } from '../../../directives/nav-list/nav-list.directive'
import { NavListItemDirective } from '../../../directives/nav-list/directives/nav-list-item/nav-list-item.directive'
import { IconButtonComponent } from '../../icon-buttons/icon-button/icon-button.component'
import { Size } from '@awork/_shared/types/size'
import { Color } from '@awork/_shared/types/color'
import { InfoBoxComponent } from '../../ui-help/info-box/info-box.component'

@Component({
  selector: 'aw-dropdown-field',
  imports: [
    NgIf,
    NgFor,
    NgClass,
    PopupComponent,
    CdkConnectedOverlay,
    OverlayModule,
    InlineSearchFieldComponent,
    NgClass,
    NavListDirective,
    NavListItemDirective,
    IconButtonComponent,
    InfoBoxComponent
  ],
  templateUrl: './dropdown-field.component.html',
  styleUrls: ['./dropdown-field.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [popIn]
})
export class DropdownFieldComponent extends WithGlobals implements OnInit, OnChanges {
  @Input() value: string // Value of selected "SelectOption"
  @Input({ required: true }) options: DropdownOption[]
  @Input({ required: true }) label: string
  @Input() disabled: boolean
  @Input() showLabelOnlyInPopup = false
  @Input() showSearch: boolean
  @Input() emptyMessage: string
  @Input() placeholder: string
  @Input() placeholderIcon: string
  @Input() clearable: boolean
  @Input() isInline = false
  @Input() popupZIndex: number = 9000
  @Input() fullWidth: boolean
  @Input() minWidth = 220
  @Input() size: Size.m | Size.s = Size.m
  @Input() errorText: string

  @Output() changed: EventEmitter<DropdownOption> = new EventEmitter<DropdownOption>()

  @ViewChild('dropdownOrigin') dropdownOriginRef: ElementRef
  @ViewChild('searchInput') searchInput: InlineSearchFieldComponent
  @ViewChild('dropdownPopup') dropdownPopup: PopupComponent

  protected readonly sizes = Size

  visibleOptions: WritableSignal<DropdownOption[]> = signal([])

  selectedIndex = 0
  selectedOption: DropdownOption

  colors = Color

  popupPosition: ConnectedPosition = {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top',
    offsetY: -31,
    offsetX: -8
  }

  constructor(private cdr: ChangeDetectorRef) {
    super()
  }

  ngOnInit(): void {
    this.selectedOption = this.getSelectedOption()
    const visibleOptions = this.getVisibleOptions(this.options || [])
    this.visibleOptions.set(visibleOptions)
    this.cdr.markForCheck()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.selectedOption = this.getSelectedOption()
    }

    if (changes.options) {
      const visibleOptions = this.getVisibleOptions(changes.options.currentValue || [])
      this.visibleOptions.set(visibleOptions)
      this.selectedOption = this.getSelectedOption()
    }

    this.cdr.markForCheck()
  }

  /**
   * Gets the visible options.
   * If the dropdown is clearable, it adds a clear option at the beginning
   * @param {DropdownOption[]} options
   * @returns {DropdownOption[]}
   */
  getVisibleOptions(options: DropdownOption[]): DropdownOption[] {
    if (this.clearable) {
      return [{ label: q.translations.common.clearSelection, value: null, isClear: true }, ...options]
    }

    return options
  }

  /**
   * Returns the selected option based on the current value
   * @returns {DropdownOption}
   */
  getSelectedOption(): DropdownOption {
    return this.options?.find(option => option.value === this.value) ?? null
  }

  /**
   * Autofocuses the input when the popup is shown
   */
  onPopupShowing(): void {
    if (this.showSearch) {
      setTimeout(() => {
        this.searchInput?.focus()
      })
    }
  }

  /**
   * Handles the list search
   * @param {string} searchText
   */
  onSearch(searchText: string): void {
    if (!searchText) {
      this.visibleOptions.set(this.options)

      return
    }

    this.visibleOptions.set(
      this.options.filter(option => option.label.toLowerCase().includes(searchText.toLowerCase()))
    )
  }

  /**
   * Handles logic when an option is selected
   * @param {number} selectedOptionIndex
   */
  onOptionSelected(selectedOptionIndex: number) {
    const selectedOption = this.visibleOptions()[selectedOptionIndex]

    if (selectedOption) {
      this.changed.emit(selectedOption)

      this.selectedOption = selectedOption
      this.selectedIndex = selectedOptionIndex
      this.cdr.markForCheck()

      this.dropdownPopup.hide()
    }
  }

  showDropdown() {
    setTimeout(() => this.dropdownPopup.show(), 100)
  }
}
